<template>
  <vac-card :title="$t('Users')">

    <div class="row">
      <div class="flex md6 xs12">
        <router-link to="/panel/users/add">
          <va-button>
            Add new user
          </va-button>
        </router-link>
      </div>

      <div class="flex xs12 md3 offset--md3">
        <va-select
                v-model="perPage"
                :label="$t('tables.perPage')"
                :options="perPageOptions"
                noClear
        />
      </div>

      <div class="flex xs12 md3">
        <va-input
                label=""
                placeholder="Search users"
                v-model.trim="searchValue"
                class="input--border"
                removable
        />
      </div>
    </div>

    <va-data-table
      :fields="fields"
      :data="getFilteredUsers"
      :per-page="perPage"
      :style="{marginTop: '24px'}"
    >

      <!--      <template slot="retailers" slot-scope="props">
        <p class="table-tag" v-for="retailer in props.rowData.retailers" v-bind:key="retailer.name">
          {{ retailer.name }}
        </p>
      </template>

      <template slot="brands" slot-scope="props">
        <p class="table-tag" v-for="brand in props.rowData.brands" v-bind:key="brand.name">{{ brand.name }}</p>
      </template>-->

      <template slot="actions" slot-scope="props">

        <a class="table-action" :href="`/panel/users/edit/${props.rowData.id}/`">
          <i class="fa fa-pencil"></i>
        </a>

        <div class="table-action" @click="showDeleteModal = true; deleteID = props.rowData.id">
          <i class="fa fa-trash"></i>
        </div>

        <!--<va-popover :message="`${$t('tables.edit')} ${props.rowData.fullName}`" placement="top">-->
        <!--<va-button flat small color="gray" icon="fa fa-pencil" :href="`/panel/users/edit/${props.rowData.id}/`" />-->
        <!--</va-popover>-->

        <!--<va-popover :message="`${$t('tables.delete')} user`" placement="top">-->
        <!--<va-button flat small color="gray" icon="fa fa-trash" @click="showDeleteModal = true; deleteID = props.rowData.id" />-->
        <!--</va-popover>-->

      </template>

    </va-data-table>

    <va-modal
      v-model="showDeleteModal"
      size="small"
      :title=" $t('Delete user?')"
      :message=" $t('If you delete user then you will can\'t recover him.') "
      :okText=" $t('modal.confirm') "
      :cancelText=" $t('modal.cancel')"
      @ok="deleteUser"
    />
  </vac-card>

</template>

<script>

  import VaButton from 'vuestic-ui/src/components/vuestic-components/va-button/VaButton';
  import API from '../../services/API/API';

  export default {
    components: { VaButton },
    data() {
      return {
        searchValue: '',
        showDeleteModal: false,
        deleteID: null,
        perPageOptions: ['100', '200', '500', '1000'],
        perPage: 100,
        users: [],
      };
    },

    created() {
      API.APIGet(`${process.env.VUE_APP_API_URL}/users`, {}, (data) => {
        this.users = data;
      });
    },

    computed: {
      getFilteredUsers () {
        if (this.searchValue) {
          return this.users.filter(i => {
            return (
            i.id.toUpperCase().includes(this.searchValue.toUpperCase()) ||
            i.username.toUpperCase().includes(this.searchValue.toUpperCase()) ||
            i.firstName.toUpperCase().includes(this.searchValue.toUpperCase()) ||
            i.lastName.toUpperCase().includes(this.searchValue.toUpperCase()) ||
            i.email.toUpperCase().includes(this.searchValue.toUpperCase())
            )
          })
        } else {
          return this.users
        }
      },
      fields() {
        return [
          {
            name: 'id',
            title: 'ID',
            sortField: 'id',
            width: '25%',
          },
          {
            name: 'username',
            title: 'Login',
            sortField: 'username',
            width: '25%',
          },
          {
            name: 'firstName',
            title: 'Name',
            sortField: 'firstName',
            width: '25%',
          },
          {
            name: 'lastName',
            title: 'Last name',
            sortField: 'lastName',
            width: '25%',
          },
          {
            name: 'email',
            title: 'E-mail',
            width: '90%',
            dataClass: 'td--no-break',
            sortField: 'email',
          },
          {
            name: '__slot:actions',
            dataClass: 'td--no-break',
          },
        ];
      },
    },

    methods: {

      deleteUser() {
        API.APIDelete(`${process.env.VUE_APP_API_URL}/users/${this.deleteID}`, {}, () => {
          this.users = this.users.filter(user => user.id !== this.deleteID);
        });
      },

      getStarMessage(user) {
        const actionName = user.starred ? this.$t('tables.unstar') : this.$t('tables.star');
        return `${actionName} ${user.fullName}`;
      },

      getStarColor(user) {
        return user.starred ? 'primary' : 'gray';
      },

      star({ id }) {
        const i = this.users.findIndex(user => user.id === id);
        this.users[i].starred = !this.users[i].starred;
      },

    },
  };
</script>

<style lang="scss">
  .inner-loading .va-table td.td--no-break {
    word-break: normal;
  }
</style>
